@media screen and (min-width: 200px) and (max-width: 992px) {
  h1 {
    text-align: center;
    margin-top: 20px;
  }

  .branding {
    margin: 0 auto;
  }

  .buttons_creche {
    margin-top: 20px;
  }
}