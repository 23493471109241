.buttonBis {
  background-color: #fcfaf7 !important;
  border: 2px solid #9e50c2 !important;
  color: #9e50c2 !important;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
}

.buttonBis:hover,
.buttonBis:active,
.buttonBis:focus {
  background-color: #9e50c2 !important;
  border: 2px solid #fcfaf7 !important;
  color: #fcfaf7 !important;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
}
.buttonBis.active {
  background-color: #9e50c2 !important;
  border: 2px solid #fcfaf7 !important;
  color: #fcfaf7 !important;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
}
