.static {
    left: 50vw;
}

.mapSlideLeft {
    left: 50vw;
    -webkit-animation: mapSlideLeft 0.5s forwards;
    animation: mapSlideLeft 0.5s forwards;
}

.mapSlideRight {
    left: 30vw;
    -webkit-animation: mapSlideRight 0.5s forwards;
    animation: mapSlideRight 0.5s forwards;
}

.modalSlideIn {
    left:-30vw;
    -webkit-animation: modalSlideIn 0.5s forwards;
    animation: modalSlideIn 0.5s forwards;
}

.modalSlideOut {
    -webkit-animation: modalSlideOut 0.5s forwards;
    animation: modalSlideOut 0.5s forwards;
}

.enlargeMap {
    -webkit-animation: enlargeMap 0.5s forwards;
    animation: enlargeMap 0.5s forwards;
}

.reduceMap {
    -webkit-animation: reduceMap 0.5s forwards;
    animation: reduceMap 0.5s forwards;
}


@-webkit-keyframes enlargeMap {
    0% { width: 50vw; }
    100% { width: 70vw; }
}
@keyframes enlargeMap {
    0% { width: 50vw; }
    100% { width: 70vw; }
}

@-webkit-keyframes reduceMap {
    0% { width: 70vw; }
    100% { width: 50vw; }
}
@keyframes reduceMap {
    0% { width: 70vw; }
    100% { width: 50vw; }
}

@-webkit-keyframes modalSlideIn {
    100% { left: 0; }
}
@keyframes modalSlideIn {
    100% { left: 0; }
}

@-webkit-keyframes modalSlideOut {
    0% { left: 0; }
    100% { left: -30vw; }
}
@keyframes modalSlideOut {
    0% { left: 0; }
    100% { left: -30vw; }
}


@media only screen and (max-width: 767px) {
    .mapSlideLeft { 
        left: 0vw;
        width: 100vw }
    .mapSlideRight { 
        left: 0vw;
        width: 100vw }
}

@media only screen and (min-width: 768px) {
    @-webkit-keyframes mapSlideLeft {
    
        100% { 
            left: 30vw;
            width: 70vw; }
    }
    @keyframes mapSlideLeft {
      
        100% { 
            left: 30vw;
            width: 70vw; }
    }
    
    @-webkit-keyframes mapSlideRight {
        0% { width: 70vw; }
        100% { 
            left: 50vw;
            width: 50vw }
    }
    @keyframes mapSlideRight {
        0% { width: 70vw; }
        100% { 
            left: 50vw;
            width: 50vw }
    }
}