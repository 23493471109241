.link,
.link:hover {
  color: #fcfaf7;
  font-family: "poppinsmedium";
  font-size: 0.9em;
  margin: 4px;
  text-decoration: underline;
}

.links {
  display: flex;
  flex-direction: column;
}
