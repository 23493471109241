.cadre{
	border: solid 0px white;
	box-shadow: 0px 0px 7px -2px rgba(0,0,0,0.25);
	background-color: transparent;
	/* margin: 20px; */
	padding: 30px;
}

.cadreContenu {
	display: flex;
	flex-wrap: wrap;
}

.surchargeButton {
	margin: 10px;
}

.colsInfos{
	padding: 5px;
	margin: 0 20px;
	display: block;
}

.form_pro_btn {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.form_pro_btn button.button a, .form_pro label {
	color:#FCFAF7;
}

.calendar {
	background-color: transparent;
	font-family: "poppinssemibold";
	color : #9e50c2;
}

.bg_purple_dark .form-check .form-check-label {
	color: #fcfaf7;
}

.form-control-file, .form-control-range {
	width: auto;
}


