/* Style général */
html {
  height: 100%;
}

/* * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} */

/*------ Heading ------*/
body {
  min-height: 100%;
  background: #fcfaf7;
  overflow-x: hidden;
  padding-bottom: 300px;
  position: relative;
}

h1 {
  font-family: "fredoka_oneregular";
  color: #390153;
  font-size: 2em;
  letter-spacing: 0.02em;
  margin-bottom: 50px;
  text-transform: uppercase;
}

h2 {
  font-family: "fredoka_oneregular";
  color: #390153;
  font-size: 1.5em;
  letter-spacing: 0.02em;
  margin-bottom: 40px;
  text-transform: uppercase;
}

h3 {
  font-family: "poppinssemibold";
  color: #9e50c2;
  font-size: 1.2em;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}

h4 {
  font-family: "poppinssemibold";
  color: #9e50c2;
  font-size: 1em;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}

p,
label {
  font-family: "poppinsregular";
  color: #9e50c2;
  font-size: 0.9em;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  margin-bottom: 10px;
}

img {
  display: block;
}

/*------ Shadows ------*/

.shadow {
  -webkit-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
}

/*------ Buttons ------*/

button,
button.btn,
a.border_btn,
button.nav {
  border-radius: 50px;
  padding: 10px 20px;
  font-family: "poppinsmedium";
  letter-spacing: 0.05em;
  font-size: 0.8em;
  border: 0;
}

button:hover,
a.border_btn:hover {
  -webkit-transition-duration: 0.7s;
  transition-duration: 0.7s;
}

/* button border radius */
button.nav {
  background-color: #390153;
  border: 2px solid #9e50c2;
  color: #fcfaf7;
}

button.button {
  background-color: #9e50c2;
  border: 2px solid #9e50c2;
  color: #fcfaf7;
}

button.button a {
  color: #fcfaf7;
  text-decoration: none;
}

button.button a:hover {
  color: #9e50c2 !important;
}

button.button:hover,
button.button:focus,
button.button:active,
button.nav:focus,
button.nav:active {
  background-color: #fcfaf7 !important;
  border: 2px solid #9e50c2 !important;
  color: #9e50c2 !important;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
}

button.nav:hover {
  background-color: #9e50c2 !important;
  border: 2px solid #390153 !important;
  color: #390153 !important;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
}

/* button border radius right */
button.full_btn {
  border-top-right-radius: 0;
  background-color: #9e50c2;
  border: 2px solid #9e50c2;
  color: #fcfaf7;
}

button.full_btn:hover {
  border-top-right-radius: 0;
  background-color: #fcfaf7;
  border: 2px solid #9e50c2;
  color: #9e50c2;
}

button.full_btn:focus {
  background-color: #fcfaf7;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  outline: none;
  border: 2px solid #9e50c2;
  color: #9e50c2;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #9e50c2;
  border: 2px solid #9e50c2;
  color: #fcfaf7;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
}

/* button border radius left */
button.border_btn,
a.border_btn {
  border-top-left-radius: 0;
  background-color: rgb(255, 255, 255);
  border: 2px solid #9e50c2;
  color: #9e50c2;
  background-color: #fcfaf7;
}

button.border_btn a {
  color: #9e50c2;
}

button.border_btn:hover,
button.border_btn a:hover,
a.border_btn:hover {
  border-top-left-radius: 0;
  border: 2px solid #9e50c2;
  background-color: #9e50c2;
  color: #fcfaf7;
  text-decoration: none;
}

button.border_btn:focus,
a.border_btn:focus {
  background-color: #9e50c2;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
  outline: none;
}

/*------ Colors ------*/

.purple_black {
  color: #390153;
}

.purple_light {
  color: #908396;
}

.yellow {
  color: #fcd260;
}

.cream {
  color: #fcfaf7;
}

/*------ Backgrounds ------*/

.bg_purple_dark {
  background-color: #390153;
  color: #fcfaf7;
}

.bg_purple_light {
  background-color: #9e50c2;
}

.bg_yellow {
  background-color: #fcd260;
}

.bg_cream {
  background-color: #fcfaf7;
}

.shadow {
  -webkit-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
}

.bg_babily {
  background: url("./assets/bg_babily.jpg") top center no-repeat;
}

.bg_team {
  background: url("./assets/bg_team.png") bottom center no-repeat;
}

.filariane {
  font-family: "poppinsregular";
  color: #9e50c2;
  font-size: 0.7em;
  letter-spacing: 0.02em;
  margin-bottom: 30px;
}

.noPadding {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.cadre2 {
  border: solid 0px white;
  box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.25);
  background-color: transparent;
  margin: 20px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cadre::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.cadre {
  -ms-overflow-style: none;
}

.breadCrumb a,
.breadCrumb a:hover {
  color: #9e50c2;
}

/*------ Form ------*/

input.search {
  height: 40px;
  background-color: #fcd260;
  color: white;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 20px;
  font-family: poppinsmedium;
  letter-spacing: 0.05em;
  font-size: 0.8em;
  border: 0;
  margin-left: 15px;
}

input:focus {
  outline: none;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
}

.form-control {
  border-radius: 10px;
  -webkit-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  border: none;
}

.form-control::-webkit-input-placeholder {
  color: rgb(158, 80, 194, 0.5);
}

.form-control_home:focus {
  -webkit-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
}

.form-control:focus {
  color: #9e50c2;
}

.form_pro_btn a:hover {
  color: #fcfaf7;
}
/*------ Fonts ------*/

.font_poppinssemibold {
  font-family: "poppinssemibold";
}

.font_poppinsmedium {
  font-family: "poppinsmedium";
}

.font_poppinsregular {
  font-family: "poppinsregular";
}

@font-face {
  font-family: "poppinssemibold";
  src: url("./fonts/poppins-semibold-webfont.woff2") format("woff2"),
    url("./fonts/poppins-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("./fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("./fonts/poppins-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("./fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("./fonts/poppins-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "fredoka_oneregular";
  src: url("./fonts/fredokaone-regular-webfont.woff2") format("woff2"),
    url("./fonts/fredokaone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
