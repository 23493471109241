.datePicker {
  border-radius: 10px;
  -webkit-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 15px 25px 40px 0px rgba(0, 0, 0, 0.2);
  border: none;
  position: absolute;
  z-index: 1;
  background-color: white
}

.calendar {
  border: none;
}

@media only screen and (max-width: 767px) {
  .datePicker { 
      right: 20px;
  }
}