/* Style footer */
.footer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

footer {
  position: relative;
}

footer::before {
  content: "";
  background-color: #9e50c2;
  width: 200%;
  height: 300px;
  position: absolute;
  left: 50%;
  top: -20%;
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

footer {
  width: 100%;
  position: relative;
  font-family: "poppinsregular";
  background-image: url("../assets/bg_footer.jpg");
  padding: 100px 0 50px 0;
  font-size: 0.8em;
  margin-top: 40px;
  z-index: 0;
}

footer ul {
  line-height: 1.8em;
}

footer li a {
  color: #fcfaf7;
}

footer li a:hover {
  color: #390153;
  text-decoration: none;
}

footer li.title {
  color: #fcd260;
  font-family: "poppinssemibold";
  font-size: 1.2em;
  margin-bottom: 5px;
}

footer .navbar-brand {
  position: absolute;
  top: -52px;
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
}

footer .networks {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

footer .networks a:hover {
  color: #390153;
}

footer .networks a {
  margin-bottom: 10px;
}

footer .networks a:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 200px) and (max-width: 768px) {
  .footer-container{
    position: relative;
  }
  footer {
    padding: 50px 0;
  }
  body{
    padding-bottom: 0 !important;
  }
  footer li {
    text-align:center;
    margin:10px 0;
  }

  .networks {
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
  }
  footer .networks a {
    margin: 20px 10px 0;
}
/* footer .navbar-brand{
  display: none;
} */
}
