/* Style header */

.navbar {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    position: sticky;
    top: 0;
    z-index: 2;
}

.navbar-nav {
    padding-left: 88px;
}

.navbar a{
   color: #FCFAF7;
   font-family: 'poppinsmedium';
   font-size: 0.9em;
   margin-right: 40px;
   position: relative;
}

.navbar a:hover, .navbar a:focus, .navbar .active{
    text-decoration: none;
    color:#390153;
}

.navbar .hover::after{
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background:#390153;
    transition:width .3s;
    position: absolute;
    bottom: -26px; 
}

.navbar .hover:hover::after, .navbar .active::after {
    width: 100%;
    transition:width .3s;
}
    
.navbar button {
    text-transform: uppercase;
    color: #FCFAF7;
}

.navbar button:hover {
    color: #390153;
    background-color: #FCFAF7;
}

.navbar-brand {
    background: url("../assets/branding-babily.png"); 
    background-size: contain;
    width: 70px;
    height: 70px;
    position: absolute;
    top: -35px;
}
.dropdown-menu {
    background-color: #FCFAF7;
    padding-bottom: 0;
    top:134%;
}

.dropdown-item{
    padding: 12px 20px;
}

.logo_animate {
    background-image: url("../assets/branding_home.png");
    width: 350px;
    height: 199px;
    margin: 0 auto;
    position: absolute;
    top: 69px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
}

@media screen and (min-width: 200px) and (max-width: 992px) {
    .navbar-nav {
        padding-left: 0;
        margin-top: 20px;
    }
    .navbar a{
        margin:10px 0;
        text-align: center;
    }
    footer{
        margin-top:0;
    }
    footer::before, footer .navbar-brand {
        display: none;
    }
    button.nav{
        display: block;
        margin: 20px auto;
    }
    .navbar .hover::after {
        display: none;
    }
    .dropdown-menu {
        width:100%
    }
    .dropdown-item {
        padding: 10px 0px;
        text-align: center;
    }
  }

